// Базовые компоненты
import Vue from 'vue'
import Router from 'vue-router'



// Компоненты дочтупные без проверки токенов
const Auth = () => import("@/components/auth-form.vue");
const Register = () => import("@/components/register-form.vue");
const Login = () => import("@/components/login-form.vue");
const recoveryPass = () => import("@/components/recoveryPass.vue");
const EmailSent = () => import("@/components/EmailSent.vue");

// Системные компоненты
const config = () => import("@/components/config-form.vue");
const Home = () => import("@/components/home-form.vue");
const VerifyEmail = () => import("@/components/VerifyEmail.vue");
const recoveryPassword = () => import("@/components/recoveryPassword.vue");

const homeform = () => import("@/components/HomeForm.vue");
const lkSettingsForm = () => import("@/components/lkSettingsForm.vue");
const userCalculatorForm = () => import("@/components/userCalculatorForm.vue");

// Конгломерат
const fs_conglomerate = () => import("@/components/conglomerate/fsConglomerate");
const profiles_in_fs_conglomerate = () => import("@/components/conglomerate/profileInFsConglomerate");
const bs_conglomerate = () => import("@/components/conglomerate/bsConglomerate");
const ws_in_bs_conglomerate = () => import("@/components/conglomerate/wsInBsConglomerate");
const mt_in_ws_conglomerate = () => import("@/components/conglomerate/mtInWsConglomerate");

// Администратор
const ConfigCalculator = () => import("@/components/administrator/ConfigCalculator");
const invitations = () => import("@/components/administrator/invitations-form");
const payments = () => import("@/components/administrator/payments-form");
const users = () => import("@/components/administrator/users-form");

// Бэк-системы

const regbs = () => import("@/components/bs/RegBS");
const changebs = () => import("@/components/bs/ChangeBS");
const settingsbs = () => import("@/components/bs/SettingsBS");
const managebs = () => import("@/components/bs/ManageBS");
const usersbs = () => import("@/components/bs/AllUsersBS");
const logsbs = () => import("@/components/bs/LogsBS");
const regproxy = () => import("@/components/bs/regProxy");


// Сервисы внутри бэк-системы
const allwsinbs = () => import("@/components/ws/allwsinbs-form");
const regws = () => import("@/components/ws/RegWS");
const changews = () => import("@/components/ws/ChangeWS");
const settingsws = () => import("@/components/ws/SettingsWS");

// Токены для запуска сервиса
const tokens_ws = () => import("@/components/tokens_ws/tokens_ws");
const tokens_ws_prod = () => import("@/components/tokens_ws/tokens_ws_prod");
const tokens_ws_uat = () => import("@/components/tokens_ws/tokens_ws_uat");
const tokens_ws_dev = () => import("@/components/tokens_ws/tokens_ws_dev");


// Методы внутри сервиса
const methodsinws = () => import("@/components/methods/MethodsInWS");
const settingsmt = () => import("@/components/methods/SettingsMT");

// Публикуемые события сервисом

// Обрабатываемые события сервисом

// Фронт-системы
const usersfs = () => import("@/components/fs/AllUsersFS");
const logsfs = () => import("@/components/fs/LogsFS");
const regfs = () => import("@/components/fs/RegFS");
const managefs = () => import("@/components/fs/ManageFS");
const settingsfs = () => import("@/components/fs/SettingsFS");
const tokenfs = () => import("@/components/fs/tokenFS");

// Профили в фронт-системах
const profilesinfs = () => import("@/components/profiles/profilesinfs-form")
const regprofilefs = () => import("@/components/profiles/regprofilefs-form")
const settingsprofile = () => import("@/components/profiles/SettingsProfile")
const configprofile = () => import("@/components/profiles/ConfigProfile")
const profiletousers = () => import("@/components/profiles/profileToUsers")
const managmentusers = () => import("@/components/profiles/managmentUsers")

// Управление балансом
const balanceForm = () => import("@/components/balance/balanceForm.vue");
const balanceShare = () => import("@/components/balance/balanceShare.vue");
const balancePayments = () => import("@/components/balance/balancePayments.vue");
const balanceExpenses = () => import("@/components/balance/balanceExpenses.vue");

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {requiresAuth: true}, // Не забыть раскодировать
      children: [
        {
          path: 'homeform',
          component: homeform,
          meta: {requiresAuth: true},
        },
        {
          path: 'fs_conglomerate',
          name: 'fs_conglomerate',
          component: fs_conglomerate,
          meta: {requiresAuth: true},
        },
        {
          path: 'profiles_in_fs_conglomerate',
          name: 'profiles_in_fs_conglomerate',
          component: profiles_in_fs_conglomerate,
          meta: {requiresAuth: true},
        },
        {
          path: 'bs_conglomerate',
          name: 'bs_conglomerate',
          component: bs_conglomerate,
          meta: {requiresAuth: true}
        },
          {
            path: 'bs_conglomerate/ws_in_bs_conglomerate',
            name: 'ws_in_bs_conglomerate',
            component: ws_in_bs_conglomerate,
            meta: {requiresAuth: true}
          },
        {
          path: 'bs_conglomerate/ws_in_bs_conglomerate/mt_in_ws_conglomerate',
          name: 'mt_in_ws_conglomerate',
          component: mt_in_ws_conglomerate,
          meta: {requiresAuth: true}
        },
        {
          path: 'configcalculator',
          name: 'ConfigCalculator',
          component: ConfigCalculator,
          meta: {requiresAuth: true, adminOnly: true},
        },
        {
          path: 'invitations',
          name: 'invitations',
          component: invitations,
          meta: {requiresAuth: true, adminOnly: true},
        },
        {
          path: 'payments',
          name: 'payments',
          component: payments,
          meta: {requiresAuth: true, adminOnly: true},
        },
        {
          path: 'users',
          name: 'users',
          component: users,
          meta: {requiresAuth: true, adminOnly: true},
        },
        {
          path: 'lksettingsform',
          name: 'lkSettingsForm',
          component: lkSettingsForm,
          meta: {requiresAuth: true},
        },
        {
          path: 'usercalculatorform',
          name: 'userCalculatorForm',
          component: userCalculatorForm,
          meta: {requiresAuth: true},
        },
        {
          path: 'balance',
          name: 'balanceForm',
          component: balanceForm,
          meta: {requiresAuth: true},
          children: [
            {
              path: 'balance_share',
              name: 'balanceShare',
              component: balanceShare,
              meta: {requiresAuth: true},
            },
            {
              path: 'balance_payments',
              name: 'balancePayments',
              component: balancePayments,
              meta: {requiresAuth: true},
            },
            {
              path: 'balance_expenses',
              name: 'balanceExpenses',
              component: balanceExpenses,
              meta: {requiresAuth: true},
            }
          ]
        },
        {
          path: 'register',
          component: Register
        },
          // Бэк-системы
        {
          path: 'regbs',
          component: regbs,
          meta: {requiresAuth: true},
        },
        {
          path: 'changebs',
          name: 'ChangeBS',
          component: changebs,
          meta: {requiresAuth: true},
        },
        {
          path: 'settingsbs',
          name: 'SettingsBS',
          component: settingsbs,
          meta: {requiresAuth: true},
        },
        {
          path: 'managebs',
          name: 'ManageBS',
          component: managebs,
          meta: {requiresAuth: true},
        },
        {
          path: 'usersbs',
          component: usersbs,
          meta: {requiresAuth: true},
        },
        {
          path: 'logsbs',
          name: 'logsbs',
          component: logsbs,
          meta: {requiresAuth: true},
        },
        {
          path: 'regproxy',
          component: regproxy,
          name: 'regproxy',
          meta: {requiresAuth: true},
        },
          // Сервисы внутри системы
        {
          path: 'allwsinbs',
          name: 'allwsinbs',
          component: allwsinbs,
          meta: {requiresAuth: true},
        },
        {
          path: 'regws',
          name: 'regws',
          component: regws,
          meta: {requiresAuth: true},
        },
        {
          path: 'changews',
          name: 'ChangeWS',
          component: changews,
          meta: {requiresAuth: true},
        },
        {
          path: 'settingsws',
          name: 'SettingsWS',
          component: settingsws,
          meta: {requiresAuth: true}
        },
          // Методы внутри сервиса
        {
          path: 'methodsinws',
          name: 'MethodsInWS',
          component: methodsinws,
          meta: {requiresAuth: true},
        },
            {
              path: 'settingsmt',
              name: 'SettingsMT',
              component: settingsmt,
              meta: {requiresAuth: true},
        },
          // Токены для сервиса
        {
          path: 'tokens_ws',
          name: 'tokens_ws',
          component: tokens_ws,
          meta: {requiresAuth: true},
          children: [
            {
              path: 'tokens_ws_prod',
              name: 'tokens_ws_prod',
              component: tokens_ws_prod,
              meta: {requiresAuth: true},
            },
            {
              path: 'tokens_ws_uat',
              name: 'tokens_ws_uat',
              component: tokens_ws_uat,
              meta: {requiresAuth: true},
            },
            {
              path: 'tokens_ws_dev',
              name: 'tokens_ws_dev',
              component: tokens_ws_dev,
              meta: {requiresAuth: true},
            }
          ]
        },
        // Фронт-системы
        {
          path: 'settingsfs',
          name: 'SettingsFS',
          component: settingsfs,
          meta: {requiresAuth: true},
        },
        {
          path: 'tokenfs',
          name: 'tokenFS',
          component: tokenfs,
          meta: {requiresAuth: true},
        },
        {
          path: 'managefs',
          name: 'ManageFS',
          component: managefs,
          meta: {requiresAuth: true},
        },
        {
          path: 'profilesinfs',
          name: 'profilesinfs',
          component: profilesinfs,
          meta: {requiresAuth: true},
        },
        {
          path: 'regprofilefs',
          name: 'regprofilefs',
          component: regprofilefs,
          meta: {requiresAuth: true},
        },
        {
          path: 'settingsprofile',
          name: 'SettingsProfile',
          component: settingsprofile,
          meta: {requiresAuth: true},
        },
        {
          path: 'profiletousers',
          name: 'profileToUsers',
          component: profiletousers,
          meta: {requiresAuth: true},
          children: [
            {
              path: 'managmentusers',
              name: 'managmentUsers',
              component: managmentusers,
              meta: {requiresAuth: true},
            },
          ],
        },
        {
          path: 'configprofile',
          name: 'ConfigProfile',
          component: configprofile,
          meta: {requiresAuth: true},
        },
        {
          path: 'usersfs',
          component: usersfs,
          meta: {requiresAuth: true},
          children: [
            {
              path: 'regfs',
              //name: 'regfs',
              component: regfs,
              meta: {requiresAuth: true},
            },
          ],
        },
        {
          path: 'logsfs',
          name: 'logsfs',
          component: logsfs,
          meta: {requiresAuth: true},
        },
      ]
    },
    {
      path: '/verifyEmail',
      name: 'VerifyEmail',
      component: VerifyEmail
    },
    {
      path: '/config',
      name: 'config',
      component: config
    },
    {
      path: '/recoveryPassword',
      name: 'recoveryPassword',
      component: recoveryPassword
    },
    {
      path: '/emailsent',
      name: 'EmailSent',
      component: EmailSent
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth,
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login
        },
        {
          path: 'register',
          component: Register
        },
        {
          path: 'recoveryPass',
          component: recoveryPass
        }
      ]
    }
  ]
})

